/* Meta */
.meta {
    width: 100vw;
    height: 87px;
    background-color: #fff;
    position: relative;
    z-index: 60;
    img {
        display: block;
        margin: 0 auto;
    }
    &.sticky {
        z-index: 60 !important;
    }
}

.owl-slider {
    width: 100vw;
    height: auto;
    position: relative;
    @include breakpoint(medium) {
        width: 100vw;
        height: 100vh;
        margin-top: -87px;
        z-index: 30;
    }
    .owl-stage-outer {
        .item {
            width: 100vw;
            height: auto;
            @include breakpoint(medium) {
                width: 100vw;
                height: 100vh;
            }
            position: relative;
        }
      }
    .owl-dots {
        position: absolute;
        bottom:0;
        left: 50%;
        transform: translateX(-50%);
            .owl-dot {
                &.active  {
                  span {
                      background-color: $primary-color;
                  }
              }
          }
      }
}


.primary-text {
    color: $primary-color;
}

.banner {
    background-color: $primary-color;
    text-align: center;
    padding: 15px 0;
    color:#fff;
    font-size: 25px;
    p {
        padding: 0;
        margin: 0;
    }
    background: #840202;
    background: -webkit-linear-gradient(#840202 0%, #550101 100%);
    background: -o-linear-gradient(#840202 0%, #550101 100%);
    background: linear-gradient(#840202 0%, #550101 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#840202', endColorstr='#550101',GradientType=0 ); /* IE6-9 */
    
    a {
        color: #fff;
    }
}



/* Allgemein */

h1 {
    color: $primary-color;
}

.spacer1 {
    padding: 30px 0;
    @include breakpoint(medium) {
        padding: 60px 0;
    }
}

.shadow {
    -webkit-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
}

.shadowTop {
    -webkit-box-shadow: 0px -3px 2px -2px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px -3px 2px -2px rgba(0,0,0,0.4);
    box-shadow: 0px -3px 2px -2px rgba(0,0,0,0.4);
}

.shadowBottom {
    -webkit-box-shadow: 0px 3px 2px -2px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 3px 2px -2px rgba(0,0,0,0.4);
    box-shadow: 0px 3px 2px -2px rgba(0,0,0,0.4);
}

.space {
    margin-bottom: 20px;
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}

time {
	display: none;
}


/* Google Maps */
.google-maps {
    width: 100vw;
    img {
        width: 100%;
        height: auto;
    }
}

/* Footer */
footer {
    -webkit-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    box-shadow: inset 0px 0px 2px 0px rgba(0,0,0,0.4);
    padding: 20px 0;
    ul {
        padding: 0;
        margin: 0;
        @include breakpoint(medium) {
             text-align: right;
        }
        li {
           list-style: none;
            display: inline-block;
            margin-right: 10px;
        }
    }
    p {
        padding: 0;
        margin: 0;
    }
}